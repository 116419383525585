const target = 'https://pppc.chineserd.hk' //正式
// const target = 'https://mall.zhixinlive.com'  //测试
// const target = 'http://192.168.10.246'  //本地

// 一些全局的config配置
const modeUrlObj = {
    // 生产环境
    'production': {
        baseURL: target,
        authBaseURL: ''
    },
    // 开发环境
    'development': {
        baseURL: '/api',
        authBaseURL: ''
    },
    // 测试环境
    'test': {
        baseURL: target,
        authBaseURL: ''
    }
}

// npm run dev // 开发环境 
// npm run test // 测试环境 
// npm run build // 正式环境打包 
// npm run build:test // 测试环境打包

export default modeUrlObj[process.env.NODE_ENV]