// 根据具体需要分类。主要是方便团队开发，容易归类。
import request from "@/script/request";

const appapi = '/appapi'

export default {
	// 获取验证码
	sendVerificationCode(params) {
		return request({
			url: appapi + '/sso/message/sendSmsForCupCode',
			method: "POST",
			headers: {
				"Content-Type": "application/json; charset=UTF-8"
			},
			data: params
		})
	},
	// 职业普通话水平测试-预约报名
	saveAppointments(data) {
		return request({
			url: appapi + '/statistics/test/center/save',
			method: "POST",
			data: data,
		})
	},
	// 职业普通话水平测试-根据时间查询预约人数
	getAppointments(data) {
		return request({
			url: appapi + "/statistics/test/center/getDate/" + data,
			method: "GET",
		});
	},
	// 职业普通话水平测试-取消预约报名
	cancelAppointments(data) {
		return request({
			url: appapi + '/statistics/test/center/cancel',
			method: "POST",
			data: data,
		})
	},
	// 职业普通话水平测试-查询预约
	getReservation(data) {
		return request({
			url: appapi + "/statistics/test/center/query/reservation",
			method: "GET",
			params: data,
		});
	}, 
	// 查询成绩
	getQuerySore(data) {
		return request({
			url: appapi + "/statistics/StatisticsTestCenterScore/query/score",
			method: "GET",
			params: data,
		});
	}, 
	// 證書查詢
	getCertificate(data,name,certificateNo ) {
		return request({
			url: appapi + "/statistics/test/center/getCertificate/"+name+'/'+certificateNo,
			method: "GET",
		});
	},
	// 查詢列表
	getRefresh(data) {
		return request({
			url: appapi + "/statistics/test/center/reservation/refresh",
			method: "GET", 
			params: data,
		});
	},
	getcs() {
		return request({
			url: "/cs",
			method: "GET",
		});
	},
	postcs(data) {
		return request({
			url: "/cs",
			method: "POST",
			headers: {
				accessToken: '',
			},
			params: data,
		});
	},
};
