<template>
	<div class="wrapper" v-show='viewShow'>
		<section class="wrapper_top">
			<img :src="require('@/assets/image/fh2.png')" alt="" @click="fh()" />
			預約查詢
		</section>
		<section class="wrapper_centent">
			<div class="uls">
				<!-- @click="item.state != 2 ? lisDetails(item) : ''" -->
				<div class="lis"  v-for="(item,index) in list" :key="index" @click="lisDetails(item)">
					<div class="lisleft">
						<div :class="item.state !=2?'spanValue3':'spanValue2' ">{{item.testName}}</div>
						<div>{{item.testDate.split(' ')[0] + ' ' + item.testStartTime + '-' + item.testEndTime}}</div>
					</div>
					<div class="lisright">
						<span class="spanValue1" v-if="item.testStatus == 0">進行中</span>
						<span class="spanValue1" v-if="item.testStatus == 1">待評分</span>
						<span class="spanValue2" v-if="item.testStatus == 2">已取消</span>
						<span class="spanValue3" v-if="item.testStatus == 3">已完成</span>
						<span class="spanValue2" v-if="item.testStatus == 4">已失效</span>
						<!-- 右 -->
						<img :src="require('@/assets/image/you.png')" alt="" v-if="item.state != 2" />
						<!-- 灰右 -->
						<img :src="require('@/assets/image/youhui.png')" alt="" v-else/>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { mapState } from 'vuex';  
import api from '@/api/index';
export default { 
	name: 'reservationInquireList',
	data() {
		return {
			list:[], 
		};
	},
	watch: {},
	components: {},
	created() {},
	mounted() { 
		// this.list = JSON.parse(window.sessionStorage.getItem('quire_list'));
		// this.list = JSON.parse(window.sessionStorage.getItem('quire_list'));
		if(window.sessionStorage.getItem('mimi')){
			this.init()
		}else{  
			this.$toast('檢查您未登録')
			this.$router.replace({ name: 'reservationInquire'})
		}
		
	},
	computed: {
		 ...mapState(['isPc','viewShow']),
		viewShow(){
		  return this.$store.state.viewShow;
		}
	},
	methods: {
		init(){
			this.getList()
		},
		getList(){
			let a = JSON.parse(decodeURI(window.sessionStorage.getItem('mimi'))); 
			let data={
				prefix:a.prefix,
				name:a.name,
				phone:a.phone
			}
			api.getRefresh(data)
			.then((res) => {
				console.log(res, 'res')
				this.$toast.clear()
				if (res.code == 0) {  
				 this.list = res.rows
				} else {
				  this.$toast(res.msg)
				}
			})
			.catch((err) => {
				this.$toast.clear()
			})
		},
		fh() { 
			this.$dialog
				.confirm({
					title: '提示',
					message: '返回您的登陸信息將會丟失，是否確定返回?'
				})
				.then(() => {
					// on confirm 
					window.sessionStorage.removeItem("mimi");
					history.go(-1); //返回首页
				})
				.catch(() => {
					// on cancel
				});
		},
		lisDetails(e){
			console.log('当前数据',e)
			
			this.$router.push({
			    name:'subscribeDetails',
			    query:{
			    	list:JSON.stringify(e)
			    }
			})
			
		}
	}
};
</script>
<style lang="scss">
@import '@/assets/css/index.scss';
// 改写部分vant  Dialog弹出框
.van-dialog {
	width: px2rem(640);
	font-size: px2rem(32);
}
.van-dialog__message {
	padding: px2rem(52) px2rem(48);
	font-size: px2rem(28);
	line-height: px2rem(40);
}
.van-dialog__header {
	padding-top: px2rem(52);
	line-height: px2rem(58);
}
.van-dialog__cancel,
.van-dialog__confirm {
	height: px2rem(96);
}
.van-button {
	font-size: px2rem(32);
}
.van-hairline--top::after {
	border-top-width: px2rem(4);
}
.van-hairline--left::after {
	border-left-width: px2rem(4);
}
.van-picker__toolbar{
	height: px2rem(100) !important;
}
.van-loading__spinner {
	width: px2rem(100) !important;
	height: px2rem(100) !important;
}
.van-toast {
	width: auto;
}
</style>
<style lang="scss" scoped>
@import '@/assets/css/index.scss';

.wrapper {
	width: 100%;
	height: 100%;
	position: relative;
	overflow-x: hidden;
	background: #fafafa;
	padding-top: px2rem(80);
	box-sizing: border-box; 
	
	.wrapper_top {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: px2rem(80);
		text-align: center;
		line-height: px2rem(80);
		font-size: px2rem(36);
		background: #ffffff;
		font-family: PingFang SC-Semibold, PingFang SC !important;
		font-weight: 600;
		color: #000000;
	
		img {
			width: px2rem(56);
			height: px2rem(56);
			position: absolute;
			left: px2rem(32);
			top: 0;
			bottom: 0;
			margin: auto;
		}
	}
	
	.wrapper_centent {
		width: 100%;
		padding: px2rem(32);
		box-sizing: border-box;
		
		.uls{
			width: 100%;
			
			.lis{
				width: 100%;
				height: px2rem(178);
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: px2rem(32);
				box-sizing: border-box;
				margin-bottom: px2rem(24);
				background: #FFFFFF;
				box-shadow: 0px px2rem(4) px2rem(50) 0px rgba(0,0,0,0.05);
				border-radius: px2rem(16);
				
				.lisleft{ 
					div{
						text-align: left;
					}
					
					div:nth-child(1){
						font-size: px2rem(40);
						font-family: pfSemibold PingFang SC;
						font-weight: 600;
						// color: #333333;
						line-height: px2rem(47);
					}
					
					div:nth-child(2){
						font-size: px2rem(24);
						font-family: PingFang SC-Regular, PingFang SC;
						font-weight: 400;
						color: #999999;
						line-height: px2rem(28);
						margin-top: px2rem(24);
					}
				}
			
			
				.lisright{
					display:flex;
					align-items: center;
					
					span{
						font-size: px2rem(32);
						font-family: PingFang SC-Semibold, PingFang SC;
						font-weight: 600; 
						line-height: px2rem(38);
					}
					 
					
					img{
						width: px2rem(56);
						height: px2rem(56);
					}
				}
			
				.spanValue1{
					color: #122AB0;
				}
				.spanValue2{
					color: #999999;
				}
				.spanValue3{
					color: #333333;
				}
			}
		}
	}
}

</style>
