import axios from 'axios';
import config from '@/config/index';//路径配置

console.log(config.baseURL)

// 创建axios实例
const service = axios.create({
    baseURL: config.baseURL,//api的base_url
    timeout: 10000 //请求超时时间
})


// 创建axios请求拦截器
service.interceptors.request.use(
    config => {
        // 这里可以定义一些config配置
        // 'Content-Type':
        // console.log(config)
        return config
    },
    error => {
        // 这里处理一些请求出错的情况
        Promise.reject(error)
    }
)

// 创建axios回调拦截器
service.interceptors.response.use(
    response => {
        const res = response.data;
        // 这里处理一些response 正常返回时的逻辑
        return res
    },
    error => {
        // 这里处理一些response 出粗错时的逻辑
        return Promise.reject(error)
    }
)

export default service